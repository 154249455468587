<template>
  <router-link
    :to="'/booking/how-to-pay/' + applicationUuid"
    v-show="isAllowed"
    class="btn btn-primary btn-block mb-1"
    >{{ buttonName }}
  </router-link>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
export default {
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'HOWTOPAY',
      actionType: 'BOOKING',
    };
  },
  methods: {},
};
</script>
